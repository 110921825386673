import { format } from "date-fns"
import PropTypes from "prop-types"
import React, { createContext, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useMutation, useQuery } from "react-query"

import { getContactPaymentMethods } from "src/api/Contacts"
import {
  calculatePrice,
  getAvailability,
} from "src/api/TransientReservationWizard"

import useDebounce from "src/hooks/use_debounce"

import {
  ADD_NEW_CONTACT,
  ADD_NEW_CONTACT_BOAT,
  ELECTRIC_MONTHLY_BILLING_STRUCTURE,
  MONTHLY_BILLING_SCHEDULE,
  NO_ELECTRIC_CHOSEN_VALUE,
  STEPS,
} from "./constants"
import {
  billingSchedulePropTypes,
  couponCodePropTypes,
  electricProductPropTypes,
  ratePropTypes,
  storageProductPropTypes,
} from "./index"

export const WizardContext = createContext({
  currentStep: STEPS.availability,
  stepEnabled: () => {},
  goToStep: () => {},
  handleArrivalChange: () => {},
  handleDepartureChange: () => {},
  storageProducts: [],
  waitlistPath: "",
})

const WizardContextProvider = ({
  children,
  initialStep = STEPS.availability,
  billingSchedules,
  storageProducts,
  electricProducts,
  rateOptions,
  couponCodes,
  waitlistPath,
  marinaSlug,
}) => {
  const { setValue, watch } = useFormContext()
  const [
    arrival,
    departure,
    electricProductId,
    billingScheduleId,
    storageProductId,
    contactId,
    contact,
    contactBoatId,
    contactBoat,
    newBoatLOA,
    newBoatBeam,
    stripeCardId,
  ] = watch([
    "arrival",
    "departure",
    "electric_product_id",
    "billing_schedule_id",
    "storage_product_id",
    "contact_id",
    "contact",
    "contact_boat_id",
    "contact_boat",
    "newBoat.lengthOverallFeet",
    "newBoat.beamFeet",
    "stripe_card_id",
  ])

  const setInitialStep = () => {
    if (initialStep === STEPS.summary && (!contactId || !contactBoatId)) {
      // Edge case handling for potential bad props being passed in from the backend.
      // Initial step cannot be set to "summary" if the contact & boat are not present.
      return STEPS.contact
    }
    return initialStep
  }
  const [currentStep, setCurrentStep] = useState(setInitialStep())

  const handleArrivalChange = (dateSelection) => {
    setValue("arrival", dateSelection)
  }

  const handleDepartureChange = (dateSelection) => {
    setValue("departure", dateSelection)
  }

  const stepEnabled = (step) => {
    if ([STEPS.availability, STEPS.contact].includes(step)) {
      return true
    } else if (step === STEPS.summary && contactId && contactBoatId) {
      return true
    } else {
      return false
    }
  }

  const goToStep = (step) => {
    if (stepEnabled(step)) {
      setCurrentStep(step)
    }
  }

  const {
    mutate: getAvailabilityData,
    isLoading: isLoadingAvailabilityData,
    isError: getAvailabilityDataError,
    data: availabilityData,
  } = useMutation(() => {
    const params = {
      check_in_date: arrival,
      check_out_date: departure,
      storage_product_id: storageProductId,
    }
    return getAvailability({ marinaSlug, params })
  })

  useEffect(() => {
    if (arrival && departure) {
      getAvailabilityData()
    }
  }, [arrival, departure, getAvailabilityData, storageProductId])

  const boatDimensionsForCalculatePrice = () => {
    if (!contactBoatId) {
      return { beam: newBoatBeam * 12, length_overall: newBoatLOA * 12 }
    } else if (contactBoatId === ADD_NEW_CONTACT_BOAT) {
      return {
        beam: (contactBoat.beam || 0) * 12,
        length_overall: (contactBoat.length_overall || 0) * 12,
      }
    } else {
      return {
        // If we have an existing contact boat selected, we do not want to send the beam and length_overall.
        beam: null,
        length_overall: null,
      }
    }
  }

  const {
    mutate: getPriceEstimate,
    isLoading: isLoadingPriceEstimate,
    isError: getPriceEstimateError,
    data: priceEstimateData,
  } = useMutation(() => {
    // eslint-disable-next-line camelcase
    const { beam, length_overall } = boatDimensionsForCalculatePrice()
    const billingSchedule = billingSchedules.find(
      ({ id }) => id === billingScheduleId
    )
    const params = {
      check_in_date: format(arrival, "yyyy-MM-dd"),
      check_out_date: format(departure, "yyyy-MM-dd"),
      storage_product_id: storageProductId,
      length_overall,
      beam,
      contact_id: contactId !== ADD_NEW_CONTACT ? contactId : null,
      contact_boat_id:
        contactBoatId !== ADD_NEW_CONTACT_BOAT ? contactBoatId : null,
      electric_product_id:
        electricProductId === NO_ELECTRIC_CHOSEN_VALUE
          ? null
          : electricProductId,
      billing_schedule: billingSchedule.schedule,
      fees_and_discounts: [],
    }
    return calculatePrice({ marinaSlug, params })
  })

  const [debouncedGetPriceEstimate] = useDebounce(getPriceEstimate)

  useEffect(() => {
    if (arrival && departure) {
      debouncedGetPriceEstimate()
    }
  }, [
    arrival,
    debouncedGetPriceEstimate,
    departure,
    storageProductId,
    newBoatLOA,
    newBoatBeam,
    contactBoat,
    contactBoatId,
    contactId,
    electricProductId,
  ])

  const [isMonthlyBilling, setIsMonthlyBilling] = useState(false)

  useEffect(() => {
    const selectedBillingSchedule = billingSchedules.find(
      (billingSchedule) => billingSchedule.id === billingScheduleId
    )
    setIsMonthlyBilling(
      selectedBillingSchedule.schedule === MONTHLY_BILLING_SCHEDULE
    )
  }, [billingScheduleId, billingSchedules])

  useEffect(() => {
    // reset the electric product to "none" IF the marina chooses monthly billing AFTER selecting a non-monthly electric product
    if (isMonthlyBilling && electricProductId !== NO_ELECTRIC_CHOSEN_VALUE) {
      const electricProduct = electricProducts.find(
        (product) => product.id === electricProductId
      )
      const isMonthlyElectric =
        electricProduct.defaultPricingStructure ===
        ELECTRIC_MONTHLY_BILLING_STRUCTURE
      if (!isMonthlyElectric) {
        setValue("electric_product_id", NO_ELECTRIC_CHOSEN_VALUE)
      }
    }
  }, [isMonthlyBilling, electricProductId, electricProducts, setValue])

  const {
    data: contactCards,
    isLoading: contactCardsLoading,
    isError: contactCardsError,
  } = useQuery(
    ["trw-contact-cards", marinaSlug, contact?.encodedId],
    () =>
      getContactPaymentMethods({ marinaSlug, contactId: contact?.encodedId }),
    {
      initialData: [],
      enabled: Boolean(contact?.encodedId),
    }
  )

  useEffect(() => {
    setValue("stripe_card_id", "")
  }, [contact, setValue])

  useEffect(() => {
    if (contactCards.length > 0 && stripeCardId === "") {
      setValue("stripe_card_id", contactCards[0].stripePaymentMethodId)
    }
  }, [contactCards, stripeCardId, setValue])

  return (
    <WizardContext.Provider
      value={{
        marinaSlug,
        currentStep,
        stepEnabled,
        goToStep,
        handleArrivalChange,
        handleDepartureChange,
        storageProducts,
        electricProducts,
        rateOptions,
        couponCodes,
        billingSchedules,
        shortTermDefaultBillingSchedule: billingSchedules.find(
          (schedule) => schedule.short_term_default
        ),
        longTermDefaultBillingSchedule: billingSchedules.find(
          (schedule) => schedule.long_term_default
        ),
        waitlistPath,
        availabilityData,
        isLoadingAvailabilityData,
        getAvailabilityDataError,
        isLoadingPriceEstimate,
        getPriceEstimateError,
        priceEstimateData,
        isMonthlyBilling,
        contactCards,
        contactCardsLoading,
        contactCardsError,
      }}
    >
      {children}
    </WizardContext.Provider>
  )
}

WizardContextProvider.defaultProps = {
  initialStep: STEPS.availability,
}

WizardContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  marinaSlug: PropTypes.string.isRequired,
  initialStep: PropTypes.oneOf(Object.values(STEPS)),
  billingSchedules: PropTypes.arrayOf(PropTypes.shape(billingSchedulePropTypes))
    .isRequired,
  storageProducts: PropTypes.arrayOf(PropTypes.shape(storageProductPropTypes))
    .isRequired,
  electricProducts: PropTypes.arrayOf(PropTypes.shape(electricProductPropTypes))
    .isRequired,
  rateOptions: PropTypes.arrayOf(PropTypes.shape(ratePropTypes)).isRequired,
  couponCodes: PropTypes.arrayOf(PropTypes.shape(couponCodePropTypes))
    .isRequired,
  waitlistPath: PropTypes.string.isRequired,
}

export default WizardContextProvider
