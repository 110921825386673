import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

import { WizardContext } from "../../WizardContextProvider"
import {
  ELECTRIC_MONTHLY_BILLING_STRUCTURE,
  NO_ELECTRIC_CHOSEN_VALUE,
} from "../../constants"
import { friendlyPricingStructureUnitName } from "../../helpers"

const ElectricSelector = () => {
  const { electricProducts, isMonthlyBilling } = useContext(WizardContext)
  const { register } = useFormContext()

  const formatElectricDisplayName = (option) =>
    `${option.name} - ${formattedCentsToDollars(
      option.pricePerUnit
    )} ${friendlyPricingStructureUnitName(option.defaultPricingStructure)}`

  const renderElectricOption = (option) => {
    const displayName = formatElectricDisplayName(option)
    const disabled =
      isMonthlyBilling &&
      option.defaultPricingStructure !== ELECTRIC_MONTHLY_BILLING_STRUCTURE

    return (
      <option key={option.id} value={option.id} disabled={disabled}>
        {displayName}
      </option>
    )
  }

  return (
    <div>
      <Form.Label htmlFor="electric-product">Electric</Form.Label>
      <Form.Select id="electric-product" {...register("electric_product_id")}>
        <option value={NO_ELECTRIC_CHOSEN_VALUE}>None</option>
        {electricProducts.map(renderElectricOption)}
      </Form.Select>
    </div>
  )
}

export default ElectricSelector
