import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import { WizardContext } from "../../WizardContextProvider"
import { friendlyInstallmentScheduleName } from "../../helpers"

const BillingScheduleSelector = () => {
  const { billingSchedules } = useContext(WizardContext)
  const { register } = useFormContext()

  return (
    <div>
      <Form.Label htmlFor="billing-schedule">Billing Schedule</Form.Label>
      <Form.Select id="billing-schedule" {...register("billing_schedule_id")}>
        {billingSchedules.map((option) => (
          <option key={option.id} value={option.id}>
            {friendlyInstallmentScheduleName(option.schedule)}
          </option>
        ))}
      </Form.Select>
    </div>
  )
}

export default BillingScheduleSelector
