import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"

import Button from "src/components/Button"
import ContactSearch from "src/components/ContactSearch/ContactSearch"
import Form from "src/components/Form"
import Heading from "src/components/Heading"

import { createContactBoat } from "src/api/ContactBoat"

import { convertBoatDimensionsToInches } from "../../Contracts/ContractsForm/tabPanels/ContactAndBoat/utils"
import { WizardContext } from "../WizardContextProvider"
import { ADD_NEW_CONTACT, ADD_NEW_CONTACT_BOAT, STEPS } from "../constants"
import { PriceEstimate, StepContent, StepWrapper } from "./shared"

const ContactStep = () => {
  const { goToStep } = useContext(WizardContext)
  const queryClient = useQueryClient()
  const {
    watch,
    setValue,
    setError,
    trigger,
    formState: { errors },
  } = useFormContext()

  const [contact, contactId, contactBoat, contactBoatId] = watch([
    "contact",
    "contact_id",
    "contact_boat",
    "contact_boat_id",
  ])

  const { mutate: saveContactBoatAndContact, isLoading } = useMutation(
    ["addNewContactBoat", contactId],
    createContactBoat,
    {
      onSuccess: ({ boat, contact }) => {
        setValue("contact_boat_id", boat.id, { shouldValidate: true })
        setValue("contact_boat", boat, { shouldValidate: true })
        if (contact) {
          setValue("contact", contact)
          setValue("contact_id", contact.id, { shouldValidate: true })
        }
        queryClient.invalidateQueries(["contactSearch-boats", contactId])
        return goToStep(STEPS.summary)
      },
      onError: (error) => {
        setError("root.saveBoatError", {
          message: error.message,
          meta: { invalidEmailUrl: error.link },
        })
      },
    }
  )

  const handleContinueClick = async () => {
    if (
      contactId === ADD_NEW_CONTACT ||
      contactBoatId === ADD_NEW_CONTACT_BOAT
    ) {
      if (!(await trigger(["contact_boat", "contact"]))) {
        return
      }
      const { name, boat_type: boatType, ...boatDimensions } = contactBoat

      const params = {
        boat: {
          name,
          boat_type: boatType,
          ...convertBoatDimensionsToInches(boatDimensions),
        },
        contact,
        contactId:
          contactId && contactId !== ADD_NEW_CONTACT ? contactId : undefined,
      }
      return saveContactBoatAndContact(params)
    } else {
      return goToStep(STEPS.summary)
    }
  }

  const renderRootError = () => {
    if (errors?.root?.saveBoatError && contactBoatId === ADD_NEW_CONTACT_BOAT) {
      const { message, meta } = errors.root.saveBoatError
      if (meta?.invalidEmailUrl) {
        return (
          <Form.Error>
            {message}{" "}
            <a className="text-link" href={meta.invalidEmailUrl}>
              View existing contacts.
            </a>
          </Form.Error>
        )
      } else {
        return <Form.Error>{message}</Form.Error>
      }
    }
  }

  return (
    <StepWrapper testId="trw-contact-step">
      <StepContent>
        <Heading.PageTitle>Contact & Boat</Heading.PageTitle>
        <div className="mt-5">
          <ContactSearch showCreateNewOutsideForm />
          {renderRootError()}
        </div>
      </StepContent>
      <PriceEstimate>
        <Button
          variant="primary"
          onClick={handleContinueClick}
          isLoading={isLoading}
          disabled={contactId === "" || !contactBoatId}
        >
          Continue
        </Button>
      </PriceEstimate>
    </StepWrapper>
  )
}

export default ContactStep
