import { differenceInDays, format } from "date-fns"
import React, { useContext, useEffect } from "react"
import { useFormContext } from "react-hook-form"

import Button from "src/components/Button"
import Heading from "src/components/Heading"

import { WizardContext } from "../WizardContextProvider"
import { STEPS } from "../constants"
import {
  ArrivalInput,
  Availability,
  BoatBeamInput,
  BoatLoaInput,
  DepartureInput,
  PriceEstimate,
  SpaceGroupsSettingsLink,
  StepWrapper,
  StorageProductSelector,
} from "./shared"
import StepContent from "./shared/StepContent"

const AvailabilityStep = () => {
  const {
    goToStep,
    waitlistPath,
    shortTermDefaultBillingSchedule,
    longTermDefaultBillingSchedule,
  } = useContext(WizardContext)
  const { watch, setValue } = useFormContext()

  const [arrival, departure, loaFeet, beamFeet] = watch([
    "arrival",
    "departure",
    "newBoat.lengthOverallFeet",
    "newBoat.beamFeet",
  ])

  useEffect(() => {
    if (differenceInDays(departure, arrival) > 30) {
      setValue("billing_schedule_id", longTermDefaultBillingSchedule.id)
    } else {
      setValue("billing_schedule_id", shortTermDefaultBillingSchedule.id)
    }
  }, [
    arrival,
    departure,
    longTermDefaultBillingSchedule.id,
    setValue,
    shortTermDefaultBillingSchedule.id,
  ])

  const goToWaitlist = () => {
    const params = {
      first_day: format(arrival, "MM/dd/yyyy"),
      last_day: departure ? format(departure, "MM/dd/yyyy") : "",
      length_overall: loaFeet || "",
      beam: beamFeet || "",
    }
    const urlParams = new URLSearchParams(params).toString()

    window.location.href = waitlistPath + "?" + urlParams
  }

  return (
    <StepWrapper testId="trw-availability-step">
      <div className="flex w-full flex-col space-y-4 lg:flex-3">
        <StepContent>
          <Heading.PageTitle>Itinerary</Heading.PageTitle>
          <div className="mt-5">
            <div className="grid grid-flow-row grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              <ArrivalInput />
              <DepartureInput />
              <StorageProductSelector />
              <BoatLoaInput />
              <BoatBeamInput />
            </div>
          </div>
        </StepContent>
        <StepContent>
          <div className="flex w-full flex-row justify-between">
            <Heading.PageTitle>Availability</Heading.PageTitle>
            <SpaceGroupsSettingsLink />
          </div>
          <Availability />
        </StepContent>
      </div>
      <PriceEstimate>
        <Button variant="primary" onClick={() => goToStep(STEPS.contact)}>
          Continue
        </Button>
        <Button variant="secondary" icon="icon-list" onClick={goToWaitlist}>
          Add to Waitlist
        </Button>
      </PriceEstimate>
    </StepWrapper>
  )
}

export default AvailabilityStep
