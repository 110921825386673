import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"
import Modal from "src/components/Modal"

import {
  cancelContactPaymentMethodSetup,
  createContactPaymentMethod,
  initializeContactPaymentMethodSetup,
} from "src/api/Manage/Contacts/PaymentMethods"

import useSetupIntent from "src/hooks/payment_method_hooks/use_setup_intent"
import useStripeForm from "src/hooks/payment_method_hooks/use_stripe_form"

import { WizardContext } from "../../../WizardContextProvider"

const AddCardModal = ({ onClose, onSuccess }) => {
  const { marinaSlug } = useContext(WizardContext)
  const { watch } = useFormContext()
  const [contact] = watch(["contact"])

  const initializePaymentMethodSetup = (_params) => {
    return initializeContactPaymentMethodSetup({
      contactId: contact.id,
      marinaId: marinaSlug,
    })
  }

  const cancelPaymentMethodSetup = (setupIntentId) => {
    return cancelContactPaymentMethodSetup({
      contactId: contact.id,
      marinaId: marinaSlug,
      setupIntentId,
    })
  }

  const createCreditCard = (setupIntentId) => {
    return createContactPaymentMethod({
      contactId: contact.id,
      marinaId: marinaSlug,
      setupIntentId,
    })
  }

  const { activeSetupIntent, stripePromise } = useSetupIntent(
    true,
    ["card"],
    false,
    initializePaymentMethodSetup,
    cancelPaymentMethodSetup
  )

  const handleClose = () => {
    cancelPaymentMethodSetup(activeSetupIntent.stripeSetupIntentId)
    onClose()
  }

  return (
    activeSetupIntent && (
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret: activeSetupIntent.clientSecret,
        }}
      >
        <ModalContent
          createCreditCard={createCreditCard}
          onClose={handleClose}
          onSuccess={onSuccess}
        />
      </Elements>
    )
  )
}

// We are forced to separate this component because the Elements component must
// wrap any component that uses the useStripe and useElements hooks.

const ModalContent = ({ createCreditCard, onClose, onSuccess }) => {
  const stripe = useStripe()
  const elements = useElements()

  const { handleSubmit, handleChange, isSubmitting, error, complete } =
    useStripeForm({
      stripe,
      elements,
      onSuccess,
      createFn: createCreditCard,
    })

  return (
    <Modal isOpen title="Add new card" onClose={onClose} size="mediumFixed">
      <Modal.Header title="Add new card" />
      <Modal.Body>
        <div className="px-1">
          <div className="mb-4">
            <PaymentElement
              options={{ terms: { card: "never" } }}
              onChange={handleChange}
            />
          </div>
          {complete && error && <Form.Error>{error.message}</Form.Error>}
        </div>
      </Modal.Body>
      <Modal.Footer
        confirmBtnText="Save"
        onClose={onClose}
        onSubmit={handleSubmit}
        confirmBtnLoading={isSubmitting}
        disabled={!!error}
      />
    </Modal>
  )
}

AddCardModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

ModalContent.propTypes = {
  createCreditCard: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default AddCardModal
