import React, { useContext, useEffect, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useQueryClient } from "react-query"
import PaymentMethodDropdownOption from "src/main/Billing/PaymentMethodDropdownOption"
import { ADD_NEW_CARD_ID } from "src/main/Billing/constants"

import Form from "src/components/Form"

import { WizardContext } from "../../../WizardContextProvider"
import AddCardModal from "./AddCardModal"

const PaymentMethodSelector = () => {
  const queryClient = useQueryClient()
  const { contactCards, marinaSlug } = useContext(WizardContext)
  const { control, setValue, getValues, watch } = useFormContext()
  const [addCardModalOpen, setAddCardModalOpen] = useState(false)

  const [stripeCardId, contact] = watch(["stripe_card_id", "contact"])

  useEffect(() => {
    if (stripeCardId === ADD_NEW_CARD_ID) {
      setAddCardModalOpen(true)
    }
  }, [stripeCardId])

  const onClose = () => {
    setAddCardModalOpen(false)
  }

  const handleCardAdded = (newCard) => {
    setValue("stripe_card_id", newCard.stripePaymentMethodId)
    queryClient.invalidateQueries([
      "trw-contact-cards",
      marinaSlug,
      contact?.encodedId,
    ])
    setAddCardModalOpen(false)
  }

  return (
    <div>
      <Form.Label htmlFor="stripe_card_id">Payment Method</Form.Label>
      <Controller
        control={control}
        name="stripe_card_id"
        render={({ field: { onChange } }) => (
          <Form.Select.Custom
            onChange={(value) => {
              onChange(value)
            }}
            value={getValues("stripe_card_id")}
            id="stripe_card_id"
          >
            <Form.Select.RichOption value="" hideCheck>
              Select a payment method
            </Form.Select.RichOption>
            {contactCards.map((card) => (
              <Form.Select.RichOption
                key={card.stripePaymentMethodId}
                value={card.stripePaymentMethodId}
                hideCheck
              >
                <PaymentMethodDropdownOption
                  paymentMethod={card}
                  includeExpirationDates
                />
              </Form.Select.RichOption>
            ))}
            <Form.Select.RichOption
              value={ADD_NEW_CARD_ID}
              key={ADD_NEW_CARD_ID}
            >
              Add a new card
            </Form.Select.RichOption>
          </Form.Select.Custom>
        )}
      />
      {addCardModalOpen ? (
        <AddCardModal onClose={onClose} onSuccess={handleCardAdded} />
      ) : null}
    </div>
  )
}

export default PaymentMethodSelector
